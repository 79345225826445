<template>
    <div class="container mt-3">
        <div class="mt-3" v-if="course">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
                    <li class="breadcrumb-item"><router-link to="/user/courses">Kursus</router-link></li>
                    <li class="breadcrumb-item"><router-link :to="`/user/courses/${course.id}`">{{ course.attributes.course_name }}</router-link></li>
                    <li class="breadcrumb-item active">Ujian</li>
                </ol>
            </nav>
        </div>
        <div class="contents mt-4" v-if="course">
            <div class="row mx-0 g-2">
                <div class="col-sm-12 col-md-4">
                    <div class="container-box sidebar-test mt-3">
                        <div class="mb-4">
                            <div class="text-center d-flex justify-content-center mb-2">
                                <h2 class="question-count">{{ questions.length }}</h2>
                            </div>
                            <div class="text-center mb-4">
                                <h4 class="fw-bold">Soalan untuk dijawab</h4>
                            </div>
                            <div class="text-center mb-4">
                                <span v-if="timer" class="timer">Masa untuk menjawab: {{timer}}</span>
                                <span v-else class="timer">Masa untuk menjawab: 00:00:00</span>
                            </div>
                            <div class="text-center mb-4">
                                <p>
                                    Anda boleh menjawab mana-mana soalan dahulu mengikut turutan atau tidak. Anda boleh kembali menjawab soalan yang ditinggalkan selagi masih ada masa.
                                </p>
                            </div>
                            <div>
                                <div v-for="(question, questionInd) in questions" :key="question.id" class="question-list">
                                    <a :href="`#question-${questionInd+1}`">
                                        <span v-if="userAnswers[questionInd] != null" class="tick"><i class="fas fa-check"></i></span>
                                        <span v-else class="untick"><i class="fas fa-check"></i></span>
                                        <span class="text-faded">{{questionInd+1}}. {{ limitString(question.question, 25) }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-8">
                    <div class="container-box mt-3">
                        <div class="mb-4">
                            <div class="row mx-0">
                                <div class="col-12">
                                    <h2>Ujian {{ course.attributes.course_name }}</h2>
                                </div>
                            </div>
                        </div>
                        <div v-for="(question, questionInd) in questions" :key="question.id" class="mb-4" :id="`question-${questionInd+1}`">
                            <div class="row mx-0">
                                <div class="col-12">
                                    <p class="fw-bolder mb-2">{{questionInd+1}}. {{ question.question }}</p>
                                    <div v-for="(item, itemInd) in question.answers" :key="item.id"
                                        class="answer mb-2 d-flex flex-row" @click="selectAnswer(itemInd, questionInd)">
                                        <div class="answer-tick" 
                                            :class="{
                                                'selected' : userAnswers[questionInd] === itemInd, 
                                                'correction' : showResult && item.is_correct && userAnswers[questionInd] != itemInd
                                            }">
                                            <span>{{ String.fromCharCode(itemInd + 1 + 64) }}</span>
                                        </div>
                                        <div class="answer-text">
                                            <p>{{ item.answer }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 pb-4">
                            <div class="float-end">
                                <button v-if="!showResult" class="btn btn-primary" @click="completeTest('complete')">Selesai Ujian</button>
                                <button v-else class="btn btn-primary" @click="$router.push(`/user/courses/${course.id}`)">Balik ke Kursus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading-spinner v-if="isLoading" />
    </div>
</template>

<script>
import moment from 'moment'
import Swal from "sweetalert2";
import API from '../../utils/API'
import { mapActions, mapGetters } from 'vuex'
import LoadingSpinner from "../../components/LoadingSpinner.vue";

export default {
    data(){
        return {
            course: null,
            questions: [],
            userAnswers: [],
            showResult: false,
            timer: null,
            intervalId: null,
            result: 0,
            isLoading: false,
            userCourseMark: []
        }
    },
    components: {
        LoadingSpinner
    },
    computed: {
        ...mapGetters(['currentUser']),
    },
    methods: {
        limitString(_str, _limit){
            if(_str.length > _limit) return _str.substring(0,_limit)
            else return _str
        },
        setTimer(){
            //setting timer
            let leftTime = 0
            if(this.course.attributes.answer_time_limit) 
                leftTime = this.course.attributes.answer_time_limit * 60
            else leftTime = 45 * 60

            let duration = moment.duration(leftTime, 'seconds');

            this.intervalId = setInterval(() => {
                // Time Out check
                if (duration.asSeconds() <= 0) {
                    this.timer = '00:00:00'
                    this.completeTest('timeout')
                    return clearInterval(this.intervalId)
                }

                duration = moment.duration(duration.asSeconds() - 1, 'seconds');
                this.timer = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
                // this.timer = duration.hours()+ ':' + duration.minutes()+ ':' + duration.seconds();
            }, 1000);
        },
        selectAnswer(_answerInd, _questionInd){
            if(!this.showResult)
                this.$set(this.userAnswers, _questionInd, _answerInd)
        },
        completeTest(_args){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary'
                },
                buttonsStyling: false
            })

            if(_args == 'timeout'){
                swalWithBootstrapButtons.fire({
                    title: 'Masa Sudah Tamat',
                    text: "Masa untuk menjawab telah tamat.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Munculkan Skor',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.checkResult()

                        Swal.fire(
                            `${this.result}%`,
                            'Ini skor anda!',
                            'success'
                        ).then(() => this.$router.push(`/user/courses/${this.course.id}`))
                    }
                })
            }
            else if(_args == 'complete'){
                swalWithBootstrapButtons.fire({
                    title: 'Selesai Ujian?',
                    text: `Anda pasti ingin menyelesaikan ujian ini?`,
                    showCancelButton: true,
                    cancelButtonText: 'Batal',
                    confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.timer = '00:00:00'
                        clearInterval(this.intervalId)

                        this.checkResult()

                        Swal.fire(
                            `${this.result}%`,
                            'Ini skor anda!',
                            'success'
                        ).then(() => this.$router.push(`/user/courses/${this.course.id}`))
                    }
                })
            }
        },
        async checkResult(){
            let correct = 0
            this.questions.forEach((question, ind) => {
                if(this.userAnswers[ind] != null)
                    if(question.answers[this.userAnswers[ind]].is_correct) 
                        correct++
            })
            this.result = parseInt((correct / this.questions.length) * 100)

            this.userCourseMark.push({
                course: this.course.id,
                mark_percentage: this.result
            })

            await API.put(`users/${this.currentUser.id}`, {
                course_mark: this.userCourseMark
            })

            this.showResult = true
        }
    },
    async mounted(){
        try{
            this.isLoading = true
            const res = await API.get(`courses/${this.$route.params.courseid}`)
            this.course = res.data.data

            //check if user already take the exam for this course
            const userWithCourseMarkRes = await API.get(
                `users/${this.currentUser.id}?populate[course_mark][populate][0]=course`
            );
            const courseMarks = userWithCourseMarkRes.data.course_mark
            if(courseMarks && courseMarks.length > 0){
                const foundCourseMark = courseMarks.find(i => i.course && i.course.id == this.$route.params.courseid)
                if (foundCourseMark) {
                    alert('Ralat! Anda hanya boleh mengambil peperiksaan ini sekali sahaja.');

                    this.isLoading = false
                    this.$router.go(-1); //redirect to previous page
                }
            } else {
                const res02 = await API.post('question-banks/shuffle-questions', { 'courseID': this.course.id })
                this.questions = res02.data.data

                //fill all the array of answers as null
                this.questions.forEach(item => {
                    this.userAnswers.push(null)
                })

                const res03 = await API.get(`users/${this.currentUser.id}?populate[course_mark][populate][0]=course`)
                this.userCourseMark = res03.data.course_mark


                this.isLoading = false
                this.setTimer()
            }
        }catch(error){
            this.isLoading = false
            window.location.reload();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

.container-box {
    padding: 30px 30px!important;
    border: 1px solid $gray-light-03;

    &.sidebar-test {
        .question-count {
            padding: 10px 15px;
            border: 2px solid $gray-regular-02;
            border-radius: 5px;
            width: auto;
            font-weight: bold;
        }
        
        .timer {
            color: $white;
            background-color: $blue-02;
            padding: 15px 20px;
            border-radius: 5px;
        }

        .question-list {
            padding: 10px 10px;
            margin-bottom: 20px;

            a {
                text-decoration: unset;
            }

            &:hover {
                cursor: pointer;
                background-color: $gray-light-09;

                .text-faded {
                    background-color: $gray-light-09;
                    background-image: linear-gradient(45deg, $black, $gray-light-09);
                }
            }

            .tick {
                margin-right: 10px;
                background-color: $green;
                color: $white;
                padding: 8px;
                border-radius: 50%;
            }

            .untick {
                margin-right: 10px;
                background-color: $gray-light-08;
                color: $gray-light-08;
                padding: 8px;
                border-radius: 50%;
            }

            .text-faded {
                background-color: $white;
                background-image: linear-gradient(45deg, $black, $white);
                background-size: 100%;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    .answer {
        padding: 5px 10px;
        font-size: 14px;

        &:hover {
            cursor: pointer;
            // background-color: $gray-light-04;
            // background-color: $green-light;

            .answer-tick {
                background-color: $gray-reqular;
                color: $white;
            }
        }

        .answer-tick {
            padding: 5px 10px;
            border-radius: 5px;

            &.selected {
                background-color: $gray-dark-03;
                color: $white;
            }
            &.correction {
                background-color: $red-light;
            }
        }

        .answer-text {
            p {
                margin-bottom: 0px!important;
                margin-top: 5px;
                margin-left: 5px;
            }
        }
    }
}
</style>